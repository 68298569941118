import React from "react";
import ReferenceItem from "../components/ReferenceItem";
 
const About = () => {
    return (
        <section>
            <h1>Ravintola-alaa maalla ja merellä</h1>
            <h3>The Ounamo</h3>
            <p>Olen Kai Ounamo, ravintola-alaa vuosikymmenet seilaillut stadilainen duunari. 
                Alan työkokemukseni pitää sisällään töitä vaihtelevissa keittiössä sekä maalla että merellä.</p>
            <hr></hr>
            <h3>Team Ounamo ja visio</h3>
            <p>Toiminimi Team Ounamo pistettiin pystyyn vuonna 2022 keikkaluontoisten töiden takia. 
                Sen jälkeen toiminta on kasvanut, ja esimerkiksi Nokkiksen vetäminen on tullut täysipäiväiseen toimintaan mukaan.</p>
            <ReferenceItem 
                review={{
                    "text": '"Se, että on sataa lajia ja kolmen kilometrin salaattipöytä ei takaa, että jengi viihtyy. Se, minkä tekee, tekee huolella. Silloin lopputulos on paras, hän (Ounamo) sanoo."',
                    "from": <a href="https://www.helsinginuutiset.fi/paikalliset/5741149">Helsingin Uutiset</a>
                }}
            />
            <hr></hr>
            <img alt="Kai Ounamo" src="/chef.jpg" width="500" className="left-align-image"></img>
        </section>
    );
};
 
export default About;