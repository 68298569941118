import ReferenceList from "../components/ReferenceList"
 
const Home = () => {
    return (
        <section>
            <h2><a href="/nokkis" className="text-link-black">Nokkis</a></h2>
            <p>Poikkea lounaalle lounasravintola Nokkikseen! Maittavat kotiruokamaiset annokset joka arkipäivä.</p>
            <p>Lue uutinen Nokkiksesta: <a href="https://www.helsinginuutiset.fi/paikalliset/5741149">https://www.helsinginuutiset.fi/paikalliset/5741149</a></p>
            <h2>
                Pitopalvelu
            </h2>
            <p>
                Haluatko järjestää juhlat tai tapahtuman, joka jää vieraiden mieleen herkullisen ruoan ansiosta? Me hoidamme sen puolestasi! 
                Pitopalvelumme tarjoaa monipuolisia vaihtoehtoja aina maittavista alkuruoista herkullisiin jälkiruokiin. 
                Käytämme laadukkaita raaka-aineita ja räätälöimme tarjoilun toiveidesi mukaan, jotta tilaisuutesi olisi juuri sellainen kuin haluat.
            </p>
            <h2>Ravintola-alan keikkatyöt</h2>
            <p>
            Etsitkö joustavaa ja ammattitaitoista apua ravintolasi toimintaan? 
            Team Ounamo tarjoaa kattavan valikoiman ravintola-alan keikkatöitä, jotka on suunniteltu helpottamaan arkeasi ja varmistamaan, 
            että ravintolasi toimii sujuvasti kaikissa tilanteissa.
            </p>
            <h2>Referenssit</h2>
            <ReferenceList />
            <h3><a href="/contact" >Ota yhteyttä!</a></h3>
            <img src="/vaakaruoka_cropped.jpeg" alt="ruoka" width={"500"} className="center"></img>
        </section>
    );
};
 
export default Home;
